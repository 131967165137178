import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { freeze, unFreeze, countItems, iconPath, moneyConverter } from '@ui/helpers'
import Variants from './Variants'
import { globalStyle } from './cart-modal-style'
import {
  SyncedProp,
  useNestedSyncedState,
  useMounted,
  usePaygates,
  useSettingDefault,
  useSetting,
  useResize
} from '@ui/hooks'
import { logger } from '@ui/analytics'
import {
  useGlobalState,
  useLocalOrder,
  isPayingWithPaypalKey,
  isMaxQuantityDiscount,
  isMaxItemNegative,
  isApplePayActive
} from '@libs/client'
import { useGtag } from '@ui/hooks/useGtag'
import getConfig from 'next/config'
import PaypalButton from '@ui/components/shared/PaypalButton'
import { GoodPolicyBadge } from '@ui/components/shared/GoodPolicyBadge'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ApplePayButton, {
  appleExpressElementOptions,
  preCalculateOrderAmount
} from '@ui/components/shared/ApplePayButton'
interface ICartModal {
  productTitle?: string
  // percentUpsellNext?: number
  activeSyncedProp: SyncedProp<boolean>
  onCheckout: (isAmazonPay?: boolean) => void
  isCheckingOutSyncedProp: SyncedProp<boolean>
  setHasApplePayBtn: Dispatch<SetStateAction<boolean>>
  hasApplePayBtn: boolean
}

const CartModal: FC<ICartModal> = ({
  productTitle,
  // percentUpsellNext,
  activeSyncedProp,
  onCheckout,
  isCheckingOutSyncedProp,
  setHasApplePayBtn,
  hasApplePayBtn
}) => {
  const paygates = usePaygates()
  const paypalEx = paygates?.paypalex
  const [localOrder] = useLocalOrder()
  const variantItems = localOrder?.items ?? []
  const [isPayingWithPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)
  const { gtagTrackViewCart } = useGtag()
  const [storeSetting] = useSetting('store')
  const version = storeSetting?.version
  const [syncedActive, setSyncedActive] = activeSyncedProp
  const [isCheckingOut] = useNestedSyncedState(isCheckingOutSyncedProp)
  const [totalItems, setTotalItems] = useState<number>(countItems(variantItems))
  const cartActive = getIsCartActive()
  const [isPurchase, setIsPuchase] = useState(true)
  const [sellpageSetting] = useSetting('sellpage')
  const discountPercent = sellpageSetting?.settings?.general?.upsell_info?.discount_percent || 0
  const preview_domain = getConfig().publicRuntimeConfig.previewdomains
  const settingDefault = useSettingDefault()
  const initiateDiscountPercentUpsell =
    sellpageSetting?.settings?.general?.upsell_info?.initiate_discount_percent || 0
  const [maxQuantityDiscount] = useGlobalState<number>(isMaxQuantityDiscount)
  const [isMaxNegative] = useGlobalState<boolean>(isMaxItemNegative)
  const [applePayActive] = useGlobalState(isApplePayActive)

  const stripePromise = useMemo(() => {
    if (
      storeSetting?.version === 'apv1' ||
      storeSetting?.version === 'apv2' ||
      storeSetting?.version === 'apv3' ||
      storeSetting?.version === 'apv21' ||
      storeSetting?.version === 'apv5' ||
      storeSetting?.version === 'apv51' ||
      storeSetting?.version === 'apv6'
    ) {
      return loadStripe(`${paygates?.creditcard?.credential_clientid}`)
    }
    return null
  }, [storeSetting?.version])

  useResize((event) => {
    if (event.target) {
      calculateMaxHeight(event.target)
    }
  }, 0)

  useEffect(() => {
    calculateMaxHeight(window)
  }, [syncedActive])

  useEffect(() => {
    const onselless_sellable = (
      settingDefault?.setting?.settings.general?.onselless_sellable ?? ''
    ).split(',')
    if (
      window.location.hostname.endsWith(preview_domain) &&
      !onselless_sellable.includes(window.location.hostname)
    ) {
      setIsPuchase(false)
    }
    const total = countItems(variantItems)
    setTotalItems(total)
    if (!total) {
      setSyncedActive(false)
    }
  }, [variantItems])

  function getIsCartActive() {
    return variantItems.length && !syncedActive
  }

  function toggleModal() {
    setSyncedActive(!syncedActive)
  }

  function openModal() {
    toggleModal()
    calculateMaxHeight(window)
  }

  function calculateMaxHeight(element: any) {
    const height = element?.innerHeight
    const width = element?.innerWidth
    if (width <= 768) {
      const vh = height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  const scrollRef = useRef(null)
  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        ;(scrollRef.current as any).scrollTo({
          top: (scrollRef.current as any).scrollHeight,
          behavior: 'smooth'
        })
      }, 300)
    }
  }

  useEffect(() => {
    if (syncedActive) {
      scrollToBottom()
      freeze()
      gtagTrackViewCart(localOrder?.items, localOrder?.items_amount)
      logger.logProductEvent('open_cart')
    } else {
      unFreeze()
      logger.logCart('close')
    }
  }, [syncedActive])

  const isMounted = useMounted()

  const onSelectedUpsell = () => {
    logger.logProductEvent('upsell_select_now')
    setSyncedActive(false)
    if (process.browser) {
      const windowWidth = window.innerWidth
      if (windowWidth > 768) {
        document
          ?.querySelector('.product-upsells')
          ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      } else {
        document
          ?.querySelector('.product-upsells')
          ?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
      }
    }
  }

  return (
    <>
      <style jsx>{globalStyle}</style>
      {isMounted && (
        <div
          className={`cart ${isPayingWithPaypal ? 'is-hidden' : ''}  ${
            version ?? storeSetting?.version
          }`}
        >
          {sellpageSetting?.settings?.general?.upsell && !isMaxNegative ? (
            <div className={`cart-v12u cart-button${cartActive ? '' : ' is-hidden'}`}>
              <a className="button is-large is-primary" onClick={() => openModal()}>
                <span className="button__title">Go to checkout</span>
                <img src={iconPath('cart.svg')} alt="Cart" />
                <div className="badge has-background-danger" onClick={() => openModal()}>
                  {totalItems}
                </div>
              </a>
            </div>
          ) : (
            <div className={`cart-button${cartActive ? '' : ' is-hidden'}`}>
              <a className="button is-large is-primary" onClick={() => openModal()}>
                <img src={iconPath('cart.svg')} alt="Cart" />
              </a>
              <div
                className="badge has-background-danger has-text-weight-bold is-size-7"
                onClick={() => openModal()}
              >
                {totalItems}
              </div>
            </div>
          )}

          <div
            className={`modal slide-right${syncedActive ? ' is-active' : ''}`}
            style={{
              visibility: syncedActive ? 'visible' : 'hidden'
            }}
          >
            <div className="modal-background" onClick={() => toggleModal()}></div>
            <div className="modal-card animated faster">
              <header
                className={`modal-card-head${
                  version === 'apv1' ||
                  version === 'apv2' ||
                  version === 'apv21' ||
                  version === 'apv3'
                    ? ' apv1'
                    : ''
                }`}
              >
                <p className="modal-card-title">Your shopping cart</p>
                <button className="delete" aria-label="close" onClick={() => toggleModal()} />
              </header>
              {version == 'gp02' || storeSetting?.version == 'gp02' ? (
                <GoodPolicyBadge
                  isShowImage
                  prefix_class="modal-card__policy"
                  highlightCont="45 days"
                  content="Return & Refund Guarantee"
                />
              ) : (
                <></>
              )}
              {/* <section className={`modal-card-body ${isUpdatingCart ? 'disabled' : ''}`}> */}
              <section className="modal-card-body">
                <div
                  className={`scrollable-content ${
                    version === 'apv1' ||
                    version === 'apv2' ||
                    version === 'apv21' ||
                    version === 'apv3'
                      ? `apv1-scrollable-content`
                      : ''
                  }`}
                  ref={scrollRef}
                >
                  <Variants title={productTitle} />
                  {sellpageSetting?.settings?.general?.upsell && !isMaxNegative && (
                    <div
                      className={`upsells${
                        version === 'apv1' ||
                        version === 'apv2' ||
                        version === 'apv21' ||
                        version === 'apv3'
                          ? ' apv1'
                          : ''
                      }`}
                    >
                      <span className="upsells__text">
                        <>
                          <span className="upsells__text__bold">
                            EXTRA{' '}
                            {discountPercent * maxQuantityDiscount + initiateDiscountPercentUpsell}%
                            OFF
                          </span>{' '}
                          for next item
                        </>
                      </span>
                      <button
                        className="button button--cta is-primary upsells__button"
                        onClick={onSelectedUpsell}
                      >
                        Select now
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className={`fixed-bottom${
                    version === 'apv1' ||
                    version === 'apv2' ||
                    version === 'apv21' ||
                    version === 'apv3'
                      ? ' apv1'
                      : ''
                  }`}
                >
                  <hr className="mt-0" />
                  <div className="total">
                    Subtotal
                    <span className="price">${moneyConverter(localOrder?.items_amount ?? 0)}</span>
                  </div>

                  {version == 'apv6' ? (
                    <div className="apv6">
                      <div className={`${localOrder?.amount ? 'd-block' : 'd-none'}`}>
                        <Elements
                          stripe={stripePromise}
                          options={{
                            ...appleExpressElementOptions,
                            amount: localOrder?.items_amount
                              ? Number(
                                  (
                                    (preCalculateOrderAmount({
                                      currentAmount: localOrder?.items_amount ?? 0,
                                      shipping_amount: localOrder?.shipping_amount ?? 0,
                                      tax_amount: localOrder?.tax_amount ?? 0,
                                      discount_amount: localOrder?.discount_amount ?? 0
                                    }) || 1) * 100
                                  ).toFixed(0)
                                )
                              : 50
                          }}
                        >
                          <ApplePayButton setHasApplePayBtn={setHasApplePayBtn} version={version} />
                        </Elements>
                      </div>
                      {hasApplePayBtn && applePayActive == 'active' ? (
                        <></>
                      ) : (
                        <button
                          className={`button button--cta is-fullwidth is-primary${
                            isCheckingOut ? ' is-loading' : ''
                          }`}
                          onClick={() => onCheckout()}
                        >
                          PROCEED TO SECURE CHECKOUT
                        </button>
                      )}
                    </div>
                  ) : (
                    <button
                      className={`button button--cta is-fullwidth is-primary${
                        isCheckingOut ? ' is-loading' : ''
                      }${
                        version === 'apv1' ||
                        version === 'apv2' ||
                        version === 'apv21' ||
                        version === 'apv3'
                          ? ' apv1'
                          : ''
                      }`}
                      onClick={() => onCheckout()}
                    >
                      PROCEED TO SECURE CHECKOUT
                    </button>
                  )}

                  {version == 'apv5' || version == 'apv51' ? (
                    <div>
                      <div className="is-divider mb-5 mt-5" data-content="or quick checkout with" />

                      <div className="express-checkout">
                        {paypalEx ? (
                          <PaypalButton
                            paypalEx={paypalEx}
                            isBuyNow={false}
                            isPurchase={isPurchase}
                            version={version}
                          />
                        ) : null}
                        <div
                          className={`${localOrder?.amount ? 'd-block' : 'd-none'} ${
                            paypalEx ? 'w-half' : 'w-full'
                          }`}
                        >
                          <Elements
                            stripe={stripePromise}
                            options={{
                              ...appleExpressElementOptions,
                              amount: localOrder?.items_amount
                                ? Number(
                                    (
                                      (preCalculateOrderAmount({
                                        currentAmount: localOrder?.items_amount ?? 0,
                                        shipping_amount: localOrder?.shipping_amount ?? 0,
                                        tax_amount: localOrder?.tax_amount ?? 0,
                                        discount_amount: localOrder?.discount_amount ?? 0
                                      }) || 1) * 100
                                    ).toFixed(0)
                                  )
                                : 50
                            }}
                          >
                            <ApplePayButton
                              setHasApplePayBtn={setHasApplePayBtn}
                              version={version}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  ) : version == 'apv6' ? (
                    <>
                      {paypalEx ? (
                        <>
                          <div
                            className="is-divider mb-5 mt-5"
                            data-content="or quick checkout with"
                          />
                          <PaypalButton
                            paypalEx={paypalEx}
                            isBuyNow={false}
                            isPurchase={isPurchase}
                            version={version}
                          />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {paypalEx ? (
                        <>
                          {version === 'apv1' ||
                          version === 'apv2' ||
                          version === 'apv21' ||
                          version === 'apv3' ? (
                            <div className="separator">
                              <hr className="line" />
                              <span>or quick checkout with</span>
                              <hr className="line" />
                            </div>
                          ) : (
                            <div
                              className="is-divider mb-5 mt-5"
                              data-content="or quick checkout with"
                            />
                          )}
                          {
                            <PaypalButton
                              paypalEx={paypalEx}
                              isBuyNow={false}
                              isPurchase={isPurchase}
                              version={version}
                            />
                          }
                        </>
                      ) : null}
                      {version == 'apv1' ||
                      version == 'apv2' ||
                      version === 'apv21' ||
                      version === 'apv3' ? (
                        <div className={`${localOrder?.amount ? 'd-block' : 'd-none'}`}>
                          <Elements
                            stripe={stripePromise}
                            options={{
                              ...appleExpressElementOptions,
                              amount: localOrder?.items_amount
                                ? Number(
                                    (
                                      (preCalculateOrderAmount({
                                        currentAmount: localOrder?.items_amount ?? 0,
                                        shipping_amount: localOrder?.shipping_amount ?? 0,
                                        tax_amount: localOrder?.tax_amount ?? 0,
                                        discount_amount: localOrder?.discount_amount ?? 0
                                      }) || 1) * 100
                                    ).toFixed(0)
                                  )
                                : 50
                            }}
                          >
                            <ApplePayButton
                              className="mt-1"
                              setHasApplePayBtn={setHasApplePayBtn}
                              version={version}
                            />
                          </Elements>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CartModal
